







































































import DrashTooltip   from '@/components/DrashTooltip.vue'
import ConvertKitForm from '@/components/ConvertKitForm.vue'
// import {getMetaInfo}  from '@/utils/getMetaInfo'

export default {
  name: 'Home',
  // metaInfo: getMetaInfo('home'),
  components: {
    DrashTooltip,
    ConvertKitForm,
  },
}
