






























import Vue from 'vue'

export default Vue.extend({
  name: 'MainNavigation',
  methods: {
    onMenuOpen(): void {
      document.documentElement.scrollTo({top:0})
      this.$root.$emit('bv::hide::popover')
    }
  }
})
