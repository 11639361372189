










export default {
  name    : 'Tooltip',
  props   : {
    position: {
      type   : String,
      default: 'top',
    },
    show: {
      type   : Boolean,
      default: false,
    },
  },
  computed: {
    identifier: function() {
      return Math.random().toString()
    },
  },
}
