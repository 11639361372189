










import MainNavigation from '@/components/MainNavigation.vue'

export default {
  components: {MainNavigation},
}
