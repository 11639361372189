









export default {
  name: 'NotFound',
   metaInfo: {title: 'טבע שלם'}
}
